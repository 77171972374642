import React from 'react';
import loadable from '@loadable/component';
import { RouteData } from '@vcc-www/api/sitecore9/SitecoreTypes';
import { ComponentIndexStoreProvider } from './useComponentIndex';
import { PageLayoutProps } from './PageLayoutProps';

const StandardPageLayout = loadable(
  () =>
    import(/* webpackChunkName: "StandardPage" */ './pageLayouts/StandardPage'),
);
const HomePageLayout = loadable(
  () => import(/* webpackChunkName: "HomePage" */ './pageLayouts/HomePage'),
);
const ProductDetailPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "ProductDetailPage" */ './pageLayouts/ProductDetailPage/ModelYear21'
    ),
);
const FaqPageLayout = loadable(
  () => import(/* webpackChunkName: "FaqPage" */ './pageLayouts/FaqPage'),
);
const InfotainmentPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "InfotainmentPage" */ './pageLayouts/InfotainmentPage'
    ),
);
const PdpMy22BevPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "PdpMy22BevPage" */ './pageLayouts/ProductDetailPage/ModelYear22/BEV'
    ),
);
const PdpMy22PhevPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "PdpMy22PhevPage" */ './pageLayouts/ProductDetailPage/ModelYear22/PHEV'
    ),
);
const PdpMy22MildHybridPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "PdpMy22MildHybridPage" */ './pageLayouts/ProductDetailPage/ModelYear22/MildHybrid'
    ),
);
const PdpMy22CrossCountryPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "PdpMy22CrossCountryPage" */ './pageLayouts/ProductDetailPage/ModelYear22/CrossCountry'
    ),
);
const PdpMy22C40PageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "PdpMy22C40Page" */ './pageLayouts/ProductDetailPage/ModelYear22/C40'
    ),
);
const PdpMy23BevPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "PdpMy23BevPage" */ './pageLayouts/ProductDetailPage/ModelYear23/BEV'
    ),
);
const PdpMy23XC40PhevPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "PdpMy23XC40PhevPage" */ './pageLayouts/ProductDetailPage/ModelYear23/XC40PHEV'
    ),
);

const PdpMy23PhevPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "PdpMy23PhevPage" */ './pageLayouts/ProductDetailPage/ModelYear23/PHEV'
    ),
);

const PdpMy23MildHybridPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "PdpMy23MildHybridPage" */ './pageLayouts/ProductDetailPage/ModelYear23/MildHybrid'
    ),
);

const ShopPageLayout = loadable(
  () => import(/* webpackChunkName: "ShopPage" */ './pageLayouts/ShopPage'),
);

// Category Pages
const CategoryPageLayout = loadable(
  () =>
    import(/* webpackChunkName: "CategoryPage" */ './pageLayouts/CategoryPage'),
);
const ElectricVehiclesPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "ElectricVehiclesPage" */ './pageLayouts/ElectricVehiclesPage'
    ),
);
const ElectrificationFaqPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "ElectrificationFaqPage" */ './pageLayouts/ElectrificationFaqPage'
    ),
);
const FuelPageLayout = loadable(
  () => import(/* webpackChunkName: "FuelPage" */ './pageLayouts/FuelPage'),
);
const RechargePageLayout = loadable(
  () =>
    import(/* webpackChunkName: "RechargePage" */ './pageLayouts/RechargePage'),
);
const Recharge2PageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "Recharge2Page" */ './pageLayouts/Recharge2Page'
    ),
);
const ValetPageLayout = loadable(
  () => import(/* webpackChunkName: "ValetPage" */ './pageLayouts/ValetPage'),
);

const VulnerabilityReportingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "VulnerabilityReportingPage" */ './pageLayouts/VulnerabilityReportingPage'
    ),
);

const ChargingPageLayout = loadable(
  () =>
    import(/* webpackChunkName: "ChargingPage" */ './pageLayouts/ChargingPage'),
);

const My22ChargingPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "My22ChargingPage" */ './pageLayouts/My22ChargingPage'
    ),
);

const PlugsurfingPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "PlugsurfingPage" */ './pageLayouts/PlugsurfingPage'
    ),
);
const PhevPageLayout = loadable(
  () => import(/* webpackChunkName: "PhevPage" */ './pageLayouts/PhevPage'),
);

// Campaign pages
const SafetyPageLayout = loadable(
  () => import(/* webpackChunkName: "SafetyPage" */ './pageLayouts/SafetyPage'),
);

const ZigZagPageLayout = loadable(
  () => import(/* webpackChunkName: "ZigZagPage" */ './pageLayouts/ZigZagPage'),
);

const ThankYouPageLayout = loadable(
  () =>
    import(/* webpackChunkName: "ThankYouPage" */ './pageLayouts/ThankYouPage'),
);

const LocalizedRedirectPageLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "LocalizedRedirectPage" */ './pageLayouts/LocalizedRedirectPage'
    ),
);
const LegalPage = loadable(
  () => import(/* webpackChunkName: "LegalPage" */ './pageLayouts/LegalPage'),
);

const DiplomatPage = loadable(
  () =>
    import(/* webpackChunkName: "DiplomatPage" */ './pageLayouts/DiplomatPage'),
);

const HeritageModelsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "HeritageModelsPage" */ './pageLayouts/HeritageModelsPage'
    ),
);

const OurStoryPage = loadable(
  () =>
    import(/* webpackChunkName: "OurStoryPage" */ './pageLayouts/OurStoryPage'),
);

const SustainabilityPage = loadable(
  () =>
    import(
      /* webpackChunkName: "SustainabilityPage" */ './pageLayouts/SustainabilityPage'
    ),
);

const SustainabilityCampaignPage = loadable(
  () =>
    import(
      /* webpackChunkName: "SustainabilityCampaignPage" */ './pageLayouts/SustainabilityCampaignPage'
    ),
);

const MiniSiteHomePage = loadable(
  () =>
    import(
      /* webpackChunkName: "MiniSiteHomePage" */ './pageLayouts/MiniSiteHomePage'
    ),
);

const C40ElectricPage = loadable(
  () =>
    import(
      /* webpackChunkName: "C40ElectricPage " */ './pageLayouts/C40ElectricPage'
    ),
);

const RecallInformationPage = loadable(
  () =>
    import(
      /* webpackChunkName: "RecallInformationPage" */ './pageLayouts/RecallInformationPage'
    ),
);

const ConceptsPage = loadable(
  () =>
    import(/* webpackChunkName: "ConceptsPage" */ './pageLayouts/ConceptsPage'),
);

const ConceptCarSubPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ConceptsCarsSubPage" */ './pageLayouts/ConceptCarSubPage'
    ),
);

const VolvoExperiencePage = loadable(
  () =>
    import(
      /* webpackChunkName: "VolvoExperiencePage " */ './pageLayouts/VolvoExperiencePage'
    ),
);

const AccessoriesPage = loadable(
  () =>
    import(
      /* webpackChunkName: "AccessoriesPage" */ './pageLayouts/AccessoriesPage'
    ),
);

const ChildSeatsPage = loadable(
  () =>
    import(/* webpackChunkName: "ChildSeats" */ './pageLayouts/ChildSeatsPage'),
);

const VolvoExperienceCentersPage = loadable(
  () =>
    import(
      /* webpackChunkName: "VolvoExperienceCentersPage " */ './pageLayouts/VolvoExperienceCentersPage'
    ),
);

const SemiConductorShortagePage = loadable(
  () =>
    import(
      /* webpackChunkName: "SemiConductorShortagePage " */ './pageLayouts/SemiConductorShortagePage'
    ),
);

const TechMomentPage = loadable(
  () =>
    import(
      /* webpackChunkName: "TechMomentPage " */ './pageLayouts/TechMomentPage'
    ),
);

const HighlightsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "HighlightsPage" */ './pageLayouts/HighlightsPage'
    ),
);

const ClimateActionPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ClimateActionPage" */ './pageLayouts/ClimateActionPage'
    ),
);

const CircularEconomyPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CircularEconomyPage" */ './pageLayouts/CircularEconomyPage'
    ),
);

const NewsOurStoryPage = loadable(
  () =>
    import(
      /* webpackChunkName: "NewsOurStoryPage" */ './pageLayouts/NewsOurStoryPage'
    ),
);

const ChildSafetyPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ChildSafetyPage" */ './pageLayouts/ChildSafetyPage'
    ),
);

const EthicalBusinessPage = loadable(
  () =>
    import(
      /* webpackChunkName: "EthicalBusinessPage" */ './pageLayouts/EthicalBusinessPage'
    ),
);

const GovernancePage = loadable(
  () =>
    import(
      /* webpackChunkName: "GovernancePage" */ './pageLayouts/GovernancePage'
    ),
);

const CareerHighlightsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CareerHighlightsPage" */ './pageLayouts/CareerHighlightsPage'
    ),
);

const CareerStudentsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CareerStudentsPage" */ './pageLayouts/CareerStudentsPage'
    ),
);

const CareerGraduatesPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CareerGraduatesPage" */ './pageLayouts/CareerGraduatesPage'
    ),
);

const CareerWellbeingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CareerWellbeingPage" */ './pageLayouts/CareerWellbeingPage'
    ),
);

const CareerBenefitsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CareerBenefitsPage" */ './pageLayouts/CareerBenefitsPage'
    ),
);

const TechHubPage = loadable(
  () =>
    import(/* webpackChunkName: "TechHubPage" */ './pageLayouts/TechHubPage'),
);

const KosicePage = loadable(
  () => import(/* webpackChunkName: "KosicePage" */ './pageLayouts/KosicePage'),
);

const CareerLifeInSwedenPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CareerLifeInSwedenPage" */ './pageLayouts/CareerLifeInSwedenPage'
    ),
);

const ContactUsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ContactUsPage" */ './pageLayouts/ContactUsPage'
    ),
);

const TechFundPage = loadable(
  () =>
    import(/* webpackChunkName: "TechFundPage" */ './pageLayouts/TechFundPage'),
);

const ResponsibleBusinessPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ResponsibleBusinessPage" */ './pageLayouts/ResponsibleBusinessPage'
    ),
);

const SupplierPortalPage = loadable(
  () =>
    import(
      /* webpackChunkName: "SupplierPortalPage" */ './pageLayouts/SupplierPortalPage'
    ),
);

const CodeOfConductPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CodeOfConductPage" */ './pageLayouts/CodeOfConductPage'
    ),
);

const SafetyHighlightsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "SafetyHighlightsPage" */ './pageLayouts/SafetyHighlightsPage'
    ),
);

const SafetyCultureVisionPage = loadable(
  () =>
    import(
      /* webpackChunkName: "SafetyCultureVisionPage" */ './pageLayouts/SafetyCultureVisionPage'
    ),
);

const SafetyFeaturePage = loadable(
  () =>
    import(
      /* webpackChunkName: "SafetyFeaturePage" */ './pageLayouts/SafetyFeaturePage'
    ),
);

const SafetyChildSafetyPage = loadable(
  () =>
    import(
      /* webpackChunkName: "SafetyChildSafetyPage" */ './pageLayouts/SafetyChildSafetyPage'
    ),
);

const SafetyOurResearchPage = loadable(
  () =>
    import(
      /* webpackChunkName: "SafetyOurResearchPage" */ './pageLayouts/SafetyOurResearchPage'
    ),
);

const SafetyHeritagePage = loadable(
  () =>
    import(
      /* webpackChunkName: "SafetyHeritagePage" */ './pageLayouts/SafetyHeritagePage'
    ),
);

const BrandProtectionPage = loadable(
  () =>
    import(
      /* webpackChunkName: "BrandProtectionPage" */ './pageLayouts/BrandProtectionPage'
    ),
);

/**
 * Maps Sitecore page template names to React page layout components.
 */
export const pageLayouts = new Map<
  string,
  React.ComponentType<PageLayoutProps>
>();
pageLayouts.set('CategoryPage', CategoryPageLayout);
pageLayouts.set('ElectricVehiclesPage', ElectricVehiclesPageLayout);
pageLayouts.set('ElectrificationFaqPage', ElectrificationFaqPageLayout);
pageLayouts.set('RechargePage', RechargePageLayout);
pageLayouts.set('Recharge2Page', Recharge2PageLayout);
pageLayouts.set('HomePage', HomePageLayout);
pageLayouts.set('StandardPage', StandardPageLayout);
pageLayouts.set('ProductDetailPage', ProductDetailPageLayout);
pageLayouts.set('PdpMy22BevPage', PdpMy22BevPageLayout);
pageLayouts.set('PdpMy22PhevPage', PdpMy22PhevPageLayout);
pageLayouts.set('PdpMy22CrossCountryPage', PdpMy22CrossCountryPageLayout);
pageLayouts.set('PdpMy22MildHybridPage', PdpMy22MildHybridPageLayout);
pageLayouts.set('PdpMy22C40Page', PdpMy22C40PageLayout);
pageLayouts.set('PdpMy23BevPage', PdpMy23BevPageLayout);
pageLayouts.set('PdpMy23XC40PhevPage', PdpMy23XC40PhevPageLayout);
pageLayouts.set('PdpMy23PhevPage', PdpMy23PhevPageLayout);
pageLayouts.set('PdpMy23MildHybridPage', PdpMy23MildHybridPageLayout);
pageLayouts.set('ShopPage', ShopPageLayout);
pageLayouts.set('FuelPage', FuelPageLayout);
pageLayouts.set('ValetPage', ValetPageLayout);
pageLayouts.set('ChargingPage', ChargingPageLayout);
pageLayouts.set('My22ChargingPage', My22ChargingPageLayout);
pageLayouts.set('PlugsurfingPage', PlugsurfingPageLayout);
pageLayouts.set('SafetyPage', SafetyPageLayout);
pageLayouts.set('ZigZagPage', ZigZagPageLayout);
pageLayouts.set('ThankYouPage', ThankYouPageLayout);
pageLayouts.set('LocalizedRedirectPage', LocalizedRedirectPageLayout);
pageLayouts.set('LegalPage', LegalPage);
pageLayouts.set('DiplomatPage', DiplomatPage);
pageLayouts.set('HeritageModelsPage', HeritageModelsPage);
pageLayouts.set('OurStoryPage', OurStoryPage);
pageLayouts.set('SustainabilityPage', SustainabilityPage);
pageLayouts.set('SustainabilityCampaignPage', SustainabilityCampaignPage);
pageLayouts.set('FaqPage', FaqPageLayout);
pageLayouts.set('MiniSiteHomePage', MiniSiteHomePage);
pageLayouts.set('InfotainmentPage', InfotainmentPageLayout);
pageLayouts.set('PhevPage', PhevPageLayout);
pageLayouts.set('C40ElectricPage', C40ElectricPage);
pageLayouts.set('RecallInformationPage', RecallInformationPage);
pageLayouts.set('ConceptsPage', ConceptsPage);
pageLayouts.set('ConceptCarSubPage', ConceptCarSubPage);
pageLayouts.set('VolvoExperiencePage', VolvoExperiencePage);
pageLayouts.set('VolvoExperienceCentersPage', VolvoExperienceCentersPage);
pageLayouts.set('VulnerabilityReportingPage', VulnerabilityReportingPage);
pageLayouts.set('SemiConductorShortagePage', SemiConductorShortagePage);
pageLayouts.set('TechMomentPage', TechMomentPage);
pageLayouts.set('HighlightsPage', HighlightsPage);
pageLayouts.set('ClimateActionPage', ClimateActionPage);
pageLayouts.set('CircularEconomyPage', CircularEconomyPage);
pageLayouts.set('NewsOurStoryPage', NewsOurStoryPage);
pageLayouts.set('ChildSafetyPage', ChildSafetyPage);
pageLayouts.set('EthicalBusinessPage', EthicalBusinessPage);
pageLayouts.set('GovernancePage', GovernancePage);
pageLayouts.set('CareerHighlightsPage', CareerHighlightsPage);
pageLayouts.set('CareerStudentsPage', CareerStudentsPage);
pageLayouts.set('CareerGraduatesPage', CareerGraduatesPage);
pageLayouts.set('CareerWellbeingPage', CareerWellbeingPage);
pageLayouts.set('CareerBenefitsPage', CareerBenefitsPage);
pageLayouts.set('TechHubPage', TechHubPage);
pageLayouts.set('KosicePage', KosicePage);
pageLayouts.set('ContactUsPage', ContactUsPage);
pageLayouts.set('CareerLifeInSwedenPage', CareerLifeInSwedenPage);
pageLayouts.set('TechFundPage', TechFundPage);
pageLayouts.set('ResponsibleBusinessPage', ResponsibleBusinessPage);
pageLayouts.set('SupplierPortalPage', SupplierPortalPage);
pageLayouts.set('CodeOfConductPage', CodeOfConductPage);
pageLayouts.set('AccessoriesPage', AccessoriesPage);
pageLayouts.set('ChildSeatsPage', ChildSeatsPage);
pageLayouts.set('SafetyHighlightsPage', SafetyHighlightsPage);
pageLayouts.set('SafetyCultureVisionPage', SafetyCultureVisionPage);
pageLayouts.set('SafetyFeaturePage', SafetyFeaturePage);
pageLayouts.set('SafetyChildSafetyPage', SafetyChildSafetyPage);
pageLayouts.set('SafetyOurResearchPage', SafetyOurResearchPage);
pageLayouts.set('SafetyHeritagePage', SafetyHeritagePage);
pageLayouts.set('BrandProtectionPage', BrandProtectionPage);

type Props = {
  routeData: RouteData;
};

const PageRenderer: React.FC<Props> = ({ routeData }) => {
  const ResolvedPageLayout =
    pageLayouts.get(routeData.templateName ?? 'StandardPage') ||
    StandardPageLayout;

  return (
    <ComponentIndexStoreProvider placeholders={routeData.placeholders}>
      <ResolvedPageLayout
        displayName={routeData.displayName}
        placeholders={routeData.placeholders}
      />
    </ComponentIndexStoreProvider>
  );
};

export default PageRenderer;
