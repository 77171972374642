export interface FeatureFlags {
  coreWebVitalsSentry?: boolean;
  coreWebVitalsReporting?: boolean;
  mergePageLoadTrackingData?: boolean;
  'shopXC40-CBV-CC'?: 'a' | 'b' | 'c' | 'd';
  volvoCarsAppCampaignPage?: 'A' | 'B';
  'use-ga-4-tracking'?: boolean;
}

export const FEATURE_FLAG_SSR_STATE_NAME = 'dotcom:state:featureFlags';
