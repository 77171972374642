import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import felaFullscreenPrefixer from 'fela-plugin-fullscreen-prefixer';
import { loadableReady } from '@loadable/component';
import * as config from '@vcc-www/constants/config';
import { createRenderer } from '@vcc-www/global-style-provider';
import { urlFromString } from '@vcc-www/volvo-cars-url';
import { readJsonState } from '@vcc-www/rendering/readState';
import { testBrowserIsSupportedOrNewer } from '@vcc-www/browsers/testBrowserIsSupportedOrNewer';
import { Dictionaries } from '@volvo-cars/content-delivery-client';
import { LayoutServiceResponse } from '@vcc-www/api/sitecore9/fetchRouteData';
import { Translator } from '@volvo-cars/react-shared-translations';
import { readSSRFeatureFlags } from '@vcc-www/dotcom-feature-flags/readSSRFeatureFlags';
import { getMarketSite } from '@volvo-cars/market-sites';
import AppRoot from './AppRoot';

const featureFlags = readSSRFeatureFlags();

Sentry.init({
  dsn: config.dotComSentryDsn,
  enabled: process.env.NODE_ENV !== 'development',
  environment: config.deployEnv,
  autoSessionTracking: false,
  ignoreErrors: [
    // TODO: there is issue created for these two, remove once fixed
    'AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22',
    'AbortError: The operation was aborted.',
    // TODO: Similar to the above, but related to autplay, separate issue
    'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
  ],
  integrations: featureFlags.coreWebVitalsSentry
    ? [new TracingIntegrations.BrowserTracing() as any]
    : [],
  tracesSampleRate: Number(
    process.env.VCC_WWW_SENTRY_TRACES_SAMPLE_RATE || 0.01,
  ),
  sampleRate: Number(process.env.VCC_WWW_SENTRY_SAMPLE_RATE || 0.1),
  beforeSend: (event: Sentry.Event) => {
    if (!testBrowserIsSupportedOrNewer(navigator.userAgent)) {
      return null;
    }
    return event;
  },
});

Sentry.configureScope(() => {
  if (typeof window !== 'undefined' && (window as any).VolvoCarsDotComConfig) {
    Sentry.setTag('sitenav', (window as any).VolvoCarsDotComConfig?.sitenav);
  }
});

if (config.deployEnv === 'dev' && window.location.pathname === '/') {
  window.location.href = '/master';
}

const url = urlFromString(window.location.href);
const dictionaries = readJsonState<{
  sharedComponents: Dictionaries;
  dotComPages: Dictionaries;
}>('dotcom:state:dictionaries');

const jssState = readJsonState<{
  sitecore: LayoutServiceResponse;
}>('dotcom:state:jss');

const container = document.getElementById('root');

const styleRenderer = createRenderer({
  enforceLonghands: true,
  plugins: [felaFullscreenPrefixer()],
});
const locale = getMarketSite(url.siteSlug).locale;

const translator = new Translator();
loadableReady(() => {
  if (!container) return;

  const appRoot = (
    <AppRoot
      url={url}
      translator={translator}
      locale={locale}
      dictionaries={dictionaries}
      routeData={jssState.sitecore.route}
      styleRenderer={styleRenderer}
      featureFlags={featureFlags}
    />
  );
  if (jssState) {
    hydrateRoot(container, appRoot);
  } else {
    const root = createRoot(container);
    root.render(appRoot);
  }

  loadGoogleTagManager();
});

function loadGoogleTagManager() {
  if (
    config.deployEnv === 'dev' ||
    url.isAuthoringUrl ||
    url.searchParams.get('perf')?.includes('nogtm')
  ) {
    return;
  }

  if (url.productSlug === 'care-by-volvo' && !window.product_id) {
    window.product_id = 'VC-CBV';
  }

  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-KZZNQG5';
  document.head.appendChild(script);
}
