// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/no-unused-vars */
import isEmpty from 'lodash/isEmpty';
import { ComponentFields, ComponentParams } from './SitecoreTypes';

interface Content {
  [key: string]: Content | unknown;
}

export type EditorialComponentProps = {
  [key: string]: string | number | boolean;
} & {
  content?: Content;
};

/**
 * Recursively get field values.
 */
export function getFieldValues(componentFields: ComponentFields = {}): Content {
  return fromEntries(
    Object.entries(componentFields)
      .filter(
        (entry): entry is [string, NonNullable<ComponentFields['any']>] =>
          !!entry[1],
      )
      .map(([name, fieldOrItems]) => {
        if (typeof fieldOrItems !== 'object') {
          return [name, fieldOrItems];
        } else if (Array.isArray(fieldOrItems)) {
          return [
            name,
            fieldOrItems.map((item) => getFieldValues(item.fields)),
          ];
        } else if ('value' in fieldOrItems) {
          const value =
            fieldOrItems.value === null ||
            (typeof fieldOrItems.value === 'object' &&
              !Array.isArray(fieldOrItems.value) &&
              isEmpty(fieldOrItems.value))
              ? undefined
              : fieldOrItems.value;
          return [name, value];
        } else if ('fields' in fieldOrItems) {
          return [name, getFieldValues(fieldOrItems.fields)];
        } else {
          // By assigning to never we ensure that TypeScript will complain if we
          // change the types to make this case possible
          const unreachable: never = fieldOrItems;
          return [name, unreachable];
        }
      }),
  );
}

/**
 * Reformat data coming from Sitecore to editorial component props.
 *
 * We get the `value` property from `fields` and put them in the `content` property,
 * while rendering `params` are passed on as root level props.
 */
export default function layoutServiceAdapter(
  fields?: ComponentFields,
  params?: ComponentParams,
): EditorialComponentProps {
  const props: EditorialComponentProps = {};
  if (fields) {
    props.content = getFieldValues(fields);
  }
  if (params) {
    Object.entries(params)
      .filter(([_, value]) => value !== '' && typeof value !== 'undefined')
      .forEach(([key, value]) => {
        props[key] = value;
      });
  }
  return props;
}

function fromEntries<T, K extends PropertyKey>(entries: Iterable<[K, T]>) {
  return [...entries].reduce(
    (obj, [key, val]) => {
      obj[key as K] = val;
      return obj;
    },
    {} as Record<K, T>,
  );
}
