import React, {
  Context,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import flatMap from 'lodash/flatMap';
import {
  ComponentRendering,
  PlaceholdersData,
} from '@vcc-www/api/sitecore9/SitecoreTypes';

type PropsType = {
  placeholders: PlaceholdersData;
  children: React.ReactNode;
};
type ComponentUidStore = { [key: string]: string[] };
const ComponentIndexContext: Context<any> = createContext(() => {});

export const ComponentIndexStoreProvider: React.FC<PropsType> = ({
  placeholders,
  children,
}) => {
  const componentUidStore = useMemo(
    () =>
      flatMap(Object.values(placeholders))
        .filter(
          (rendering): rendering is ComponentRendering => 'uid' in rendering,
        )
        .reduce((prev, curr) => {
          if (curr.componentName in prev) {
            prev[curr.componentName].push(curr.uid ?? '');
          } else {
            prev[curr.componentName] = [curr.uid ?? ''];
          }
          return prev;
        }, {} as ComponentUidStore),
    [placeholders],
  );

  const getRenderingComponentIndex = useCallback(
    (rendering: ComponentRendering) => {
      if (!(rendering.componentName in componentUidStore) || !rendering.uid) {
        return -1;
      }
      return componentUidStore[rendering.componentName].indexOf(rendering.uid);
    },
    [componentUidStore],
  );

  return (
    <ComponentIndexContext.Provider value={getRenderingComponentIndex}>
      {children}
    </ComponentIndexContext.Provider>
  );
};

export const useComponentIndexStore = () => useContext(ComponentIndexContext);
