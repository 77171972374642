import { LayoutServiceContextData } from '@sitecore-jss/sitecore-jss';
import * as config from '@vcc-www/constants/config';
import { SiteSlug, validateSiteSlug } from '@volvo-cars/market-sites';
import { getSitecoreLanguage } from './getSitecoreLanguage';
import { RouteData } from './SitecoreTypes';

/**
 * A reply from the Sitecore Layout Service
 */
export type LayoutServiceResponse = LayoutServiceContextData & {
  route: RouteData;
};

const JSS_ENDPOINT = `${config.sitecoreLayoutServiceHost}${config.sitecoreLayoutServiceEndpoint}`;

export class LayoutServiceError extends Error {
  name = 'LayoutServiceError';
  status: number;
  url: string;
  data?: LayoutServiceResponse;

  constructor(
    message: string,
    resp: Response,
    data: LayoutServiceError['data'],
  ) {
    super(message);
    this.status = resp.status;
    this.url = resp.url;
    this.data = data;
    Object.setPrototypeOf(this, LayoutServiceError.prototype);
  }

  static async fromResponse(resp: Response) {
    let data;
    const contentType = resp.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      try {
        data = await resp.json();
      } catch {
        // noop
      }
    }
    return new LayoutServiceError(
      resp.statusText,
      resp,
      data?.sitecore ?? data,
    );
  }
}

/**
 * Makes a request to Sitecore Layout Service for the specified relative route item path.
 */
export default async function fetchRouteData(
  itemPath: string,
  { siteSlug }: { siteSlug?: SiteSlug } = {},
  headers?: {
    /**
     * Auth headers required in test and qa.
     */
    TESTWWW: string;
    QAWWW: string;
  },
): Promise<LayoutServiceResponse> {
  validateSiteSlug(siteSlug);
  const url = new URL(JSS_ENDPOINT);
  url.searchParams.set('item', itemPath);
  url.searchParams.set('sc_lang', getSitecoreLanguage(siteSlug) ?? '');
  url.searchParams.set('sc_site', siteSlug);
  const resp = await fetch(url.href, { headers });
  if (!resp.ok) {
    throw await LayoutServiceError.fromResponse(resp);
  }
  const data = await resp.json();
  if (!data.sitecore) {
    throw new LayoutServiceError('Invalid API response', resp, data);
  }
  if (!data.sitecore.route) {
    throw new LayoutServiceError('Invalid route data', resp, data);
  }
  return data.sitecore;
}
