import { getMarketSite, SiteSlug } from '@volvo-cars/market-sites';

const sitecoreLanguageOverrideBySiteSlug: Partial<Record<SiteSlug, string>> = {
  az: 'az',
  ba: 'hr-BA',
  dk: 'da',
  'en-ae': 'en-AS',
  'en-bh': 'en-BM',
  'en-eg': 'en-ER',
  'en-jo': 'en-JE',
  'en-kw': 'en-KI',
  'en-om': 'en-MO',
  'en-qa': 'en-FM',
  'en-sa': 'en-SB',
  'en-th': 'en-TO',
  'en-vn': 'en-VI',
  kh: 'en-CM',
  lb: 'en-LR',
  lk: 'en-LS',
  master: 'en',
  me: 'sr-Cyrl-ME',
  mm: 'en-MG',
  no: 'nb-NO',
  uz: 'uz',
};

export function getSitecoreLanguage(siteSlug?: SiteSlug): string {
  const defaultLanguage = getMarketSite(siteSlug).locale;
  if (!siteSlug) return defaultLanguage;
  return sitecoreLanguageOverrideBySiteSlug[siteSlug] || defaultLanguage;
}
