import React, { StrictMode } from 'react';
import { ConfigProvider } from 'vcc-ui';
import AppProviders, { AppProvidersProps } from '@vcc-www/app-providers';
import { RouteData } from '@vcc-www/api/SitecoreTypes';
import { Dictionaries } from '@volvo-cars/content-delivery-client';
import { publicPath } from '@vcc-www/constants/config';
import { SharedComponentsDictionariesProvider } from '@vcc-www/shared-dictionaries';
import { FeatureFlagsProvider } from '@vcc-www/dotcom-feature-flags/FeatureFlagsProvider';
import type { FeatureFlags } from '@vcc-www/dotcom-feature-flags/constants';
import {
  Translator,
  TranslatorProvider,
} from '@volvo-cars/react-shared-translations';
import RouteHandler from './RouteHandler';
import { DotComDictionariesProvider } from './DotComDictionaryProvider';
// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

__webpack_public_path__ = publicPath;
const AppRoot: React.FC<
  AppProvidersProps & {
    routeData?: RouteData;
    dictionaries: Record<'dotComPages' | 'sharedComponents', Dictionaries>;
    locale: string;
    featureFlags: FeatureFlags;
    translator: Translator;
  }
> = ({
  routeData,
  dictionaries,
  locale,
  featureFlags,
  translator,
  ...props
}) => {
  return (
    <StrictMode>
      <ConfigProvider config={{ locale, linkComponent: 'a' }}>
        <AppProviders
          {...props}
          routeFields={routeData?.fields as AppProvidersProps['routeFields']}
        >
          <FeatureFlagsProvider flags={featureFlags}>
            <SharedComponentsDictionariesProvider
              locale={locale}
              dictionaries={dictionaries.sharedComponents}
            >
              <DotComDictionariesProvider
                locale={locale}
                dictionaries={dictionaries.dotComPages}
              >
                <TranslatorProvider locale={locale} translator={translator}>
                  <RouteHandler initialRouteData={routeData} />
                </TranslatorProvider>
              </DotComDictionariesProvider>
            </SharedComponentsDictionariesProvider>
          </FeatureFlagsProvider>
        </AppProviders>
      </ConfigProvider>
    </StrictMode>
  );
};

export default AppRoot;
