import React from 'react';
import {
  RouteFields,
  RouteFieldsProvider,
} from '@vcc-www/api/sitecore9/useRouteFields';
import { BreakpointsProvider, ScrollLockProvider } from '@vcc-www/hooks';
import { OverlayCounterProviderDEPRECATED } from '@vcc-package/overlays/DEPRECATED';
import {
  VolvoCarsUrlProvider,
  VolvoCarsUrl,
  VolvoCarsUrlProviderProps,
} from '@vcc-www/volvo-cars-url';
import { getMarketSite } from '@volvo-cars/market-sites';
import ComposeComponents, { ComponentSpec } from '@vcc-www/compose-components';
import GlobalStyleProvider, {
  StyleRenderer,
} from '@vcc-www/global-style-provider';

export type AppProvidersProps = {
  url: VolvoCarsUrl;
  beforeUrlUpdate?: VolvoCarsUrlProviderProps['beforeUrlUpdate'];
  styleRenderer?: StyleRenderer;
  routeFields?: RouteFields;
  additionalProviders?: ComponentSpec[];
  targetDocument?: Document;
};

export const Providers: React.FC<
  React.PropsWithChildren<Omit<AppProvidersProps, 'url'>>
> = ({ children, routeFields, additionalProviders = [] }) => {
  const providers: ComponentSpec[] = [
    ...additionalProviders,
    [ScrollLockProvider],
    [BreakpointsProvider],
  ];

  return (
    <RouteFieldsProvider initialRouteFields={routeFields}>
      <ComposeComponents components={providers}>{children}</ComposeComponents>
    </RouteFieldsProvider>
  );
};

const AppProviders: React.FC<React.PropsWithChildren<AppProvidersProps>> = ({
  styleRenderer,
  targetDocument,
  children,
  url,
  beforeUrlUpdate,
  ...props
}) => {
  const { siteSlug } = url;
  const { languageDirection } = getMarketSite(siteSlug as string);
  const providers: ComponentSpec[] = [
    [
      GlobalStyleProvider,
      { renderer: styleRenderer, direction: languageDirection, targetDocument },
    ],
    [OverlayCounterProviderDEPRECATED],
  ];

  return (
    <VolvoCarsUrlProvider url={url} beforeUrlUpdate={beforeUrlUpdate}>
      <Providers {...props} additionalProviders={providers}>
        {children}
      </Providers>
    </VolvoCarsUrlProvider>
  );
};

export default AppProviders;
