// Cache the latest measurement because when scroll lock is enabled this would return 0
let cachedScrollBarWidth: number | undefined = undefined;
export const getScrollBarWidth = (): number => {
  if (typeof window === 'undefined') return 0;

  // return cached value if we have some
  if (typeof cachedScrollBarWidth === 'number') {
    return cachedScrollBarWidth;
  }

  const element = document.createElement('div');
  const { style } = element;

  style.display = 'block';
  style.position = 'absolute';
  style.width = '100px';
  style.height = '100px';
  style.left = '-999px';
  style.top = '-999px';
  style.overflow = 'scroll';

  document.body.insertBefore(element, null);

  const { clientWidth } = element;

  // if element still has no width it means DOM is not ready yet
  if (clientWidth === 0) {
    // remove the element and skip the caching
    document.body.removeChild(element);
    return 0;
  }

  // clientWidth is the total width of the block - scrollbar width, thus, to get the scrollbar width
  // it works well for desktop, but in mobile browsers scrollbar as absolutely positioned and i
  // don't know anyway to determine mobile browser's scrollbar width, so for mobile that value will be always 0.
  cachedScrollBarWidth = 100 - clientWidth;

  document.body.removeChild(element);

  return cachedScrollBarWidth;
};
