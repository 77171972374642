'use client';
import React, { useState, useCallback, createContext, useContext } from 'react';

type Context = {
  openedOverlayCount: number;
  increaseOpenedOverlayCount: () => void;
  decreaseOpenedOverlayCount: () => void;
};

const OverlayCounterContext = createContext<Context>({
  openedOverlayCount: 0,
  increaseOpenedOverlayCount: () => {},
  decreaseOpenedOverlayCount: () => {},
});

/**
 *  @deprecated
 *  Use OverlayStacker component from '@volvo-cars/react-overlay' instead
 *  https://vcc-ui.vercel.app/docs/packages/react-overlay
 **/
export const OverlayCounterProviderDEPRECATED: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [openedOverlayCount, set] = useState(0);

  const increaseOpenedOverlayCount = useCallback(
    () => set((count) => count + 1),
    [],
  );
  const decreaseOpenedOverlayCount = useCallback(
    () => set((count) => count - 1),
    [],
  );

  return (
    <OverlayCounterContext.Provider
      value={{
        openedOverlayCount,
        increaseOpenedOverlayCount,
        decreaseOpenedOverlayCount,
      }}
    >
      {children}
    </OverlayCounterContext.Provider>
  );
};

/**
 *  @deprecated
 *  Use Overlay component from '@volvo-cars/react-overlay' instead
 *  https://vcc-ui.vercel.app/docs/packages/react-overlay
 **/
export const useOverlayCounterDEPRECATED = () =>
  useContext(OverlayCounterContext);
