import memoize from 'lodash/memoize';

/**
 * Reads a JSON state string that was saved with `getJsonStateSsrString`.
 */
export const readJsonState = memoize(function readState<T>(id: string): T {
  const stateNode = document.getElementById(id);
  if (!stateNode) {
    throw new Error(`Missing SSR state node ${id}`);
  }
  return JSON.parse(stateNode.innerHTML);
});
