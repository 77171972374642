import React, { useEffect, useState } from 'react';
import { useUpdateRouteFields } from '@vcc-www/api/sitecore9';
import fetchRouteData from '@vcc-www/api/sitecore9/fetchRouteData';
import { RouteData } from '@vcc-www/api/sitecore9/SitecoreTypes';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import PageRenderer from '../jss/PageRenderer';

function useRouteData(initialState?: RouteData | null) {
  const [routeData, setRouteData] = useState(initialState);
  const { siteSlug, contentPath } = useVolvoCarsUrl();
  const setRouteFields = useUpdateRouteFields();
  useEffect(() => {
    if (!initialState) {
      updateRouteData();
    }
    async function updateRouteData() {
      try {
        const { route } = await fetchRouteData(contentPath, { siteSlug });
        setRouteData(route);
        setRouteFields(route.fields);
      } catch (error) {
        console.warn(`Failed to fetch route data for ${contentPath}`, error);
        if (error.status === 404) {
          try {
            const { route } = await fetchRouteData('/404', { siteSlug });
            setRouteData(route);
            setRouteFields(route.fields);
          } catch (error) {
            console.warn('Failed to fetch route data for 404 page', error);
            setRouteData(null);
          }
        } else {
          setRouteData(null);
        }
      }
    }
  }, [initialState, siteSlug, contentPath, setRouteFields]);
  return routeData;
}

const RouteHandler: React.FC<{
  initialRouteData?: RouteData;
}> = ({ initialRouteData }) => {
  const routeData = useRouteData(initialRouteData);

  if (!routeData) {
    return null;
  }

  return <PageRenderer routeData={routeData} />;
};

export default RouteHandler;
